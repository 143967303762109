import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import {
  setLocale,
  setActivePillar,
  activateSlider,
  updateSlide,
  setQueryString,
} from "../../state/actions"
import { connect } from "react-redux"
import styles from "./index.module.scss"
import Arrow from "../Icons/Arrow"
import Logo from "../../assets/icons/logo.svg"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.logo = null
    this.tl = null
    this.nav = null
    this.hiddenNav = null
    this.navTL = null
    this.state = {
      isMenuOpened: false,
    }
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollToPlugin)
    const { switchLanguage, context, setQueryString, location } = this.props
    context.locale === "en" ? switchLanguage("en") : switchLanguage("tc")

    if (location.search) {
      setQueryString(location.search)
    }
  }

  handleClick = () => {
    if (this.state.isMenuOpened) {
      this.setState({ isMenuOpened: false })
    } else {
      this.setState({ isMenuOpened: true })
    }
  }

  closeMenu = () => {
    this.setState({ isMenuOpened: false })
  }

  render() {
    const {
      lang: { locale },
      location,
      setActivePillar,
      activateSlider,
      updateSlide,
      URL,
    } = this.props

    const queryString = URL.queryString ? URL.queryString : ""

    const { isMenuOpened } = this.state
    const isChinese = locale === "tc"
    const isHome =
      location.pathname === "/" ||
      location.pathname === "/en/" ||
      location.pathname === "/en" ||
      location.pathname === "/tc" ||
      location.pathname === "/tc/"

    return (
      <>
        <nav className="z-1000 md:justify-end absolute top-0 left-0 flex justify-between w-full h-40">
          <div className="locale-swticher md:ml-8 md:hidden self-center ml-16 text-white">
            {isChinese ? (
              <Link
                to={
                  location.pathname === "/tc"
                    ? `${location.pathname.replace(
                        "/tc/",
                        "/en"
                      )}${queryString}`
                    : location.pathname === "/tc/"
                    ? `${location.pathname.replace("/tc", "/en")}${queryString}`
                    : `/en${location.pathname}${queryString}`
                }
              >
                ENG
              </Link>
            ) : (
              <Link
                to={`${location.pathname.replace("/en/", "/")}${queryString}`}
              >
                中文
              </Link>
            )}
          </div>
          <div
            className={`w-16 ml-8 hidden md:block self-center relative cursor-pointer z-1000 ${
              styles.hamburger
            } ${isMenuOpened ? styles.isMenuOpened : ""}`}
            onClick={this.handleClick}
          >
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
            <span className={styles.hamburgerLine}></span>
          </div>
          <div className={`main-nav self-center ${styles.mainNav} md:hidden`}>
            <ul
              className={`flex text-white light-font py-4 border-white border-solid border-b border-t text-3xl cursor-pointer ${
                !isHome ? "justify-between" : "justify-center"
              }`}
            >
              {!isHome ? (
                <li className="self-center px-8">
                  {isChinese ? (
                    <Link to={`/${queryString}`}>主頁</Link>
                  ) : (
                    <Link to={`/en${queryString}`}>Home</Link>
                  )}
                </li>
              ) : (
                ""
              )}
              <li
                className={`px-8 self-center relative cursor-pointer ${styles.discover}`}
              >
                <span className="">
                  {isChinese ? "探索BMW旅程故事" : "Discover BMW Stories"}
                </span>
                <ul
                  className={`${styles.tooltip} text-black text-xl opacity-0 invisible`}
                >
                  <NavItem
                    en="Adventure"
                    cn="敢於冒險"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                  <NavItem
                    en="Freedom"
                    cn="遨翔自得"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                  <NavItem
                    en="Independence"
                    cn="自主創新"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                  <NavItem
                    en="Inspiration"
                    cn="啟迪靈感"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                  <NavItem
                    en="Progress"
                    cn="一瞬千里"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                  <NavItem
                    en="Wisdom"
                    cn="遠見卓識"
                    isChinese={isChinese}
                    setActivePillar={setActivePillar}
                    updateSlide={updateSlide}
                    activateSlider={activateSlider}
                    queryString={queryString}
                  />
                </ul>
              </li>
            </ul>
          </div>
          <div className="md:mr-8 md:w-16 self-center w-24 mr-16">
            {isChinese ? (
              <a href="https://www.bmwhk.com/zh/index.html">
                <img src={Logo} alt="" />
              </a>
            ) : (
              <a href="https://www.bmwhk.com/en/index.html">
                <img src={Logo} alt="" />
              </a>
            )}
          </div>
          <div
            className={`${
              styles.hiddenNav
            } fixed top-0 left-0 w-full h-screen ${
              isMenuOpened ? "opacity-1 visible" : "opacity-0 invisible"
            }`}
          >
            <ul
              className={`${styles.hiddenNavList} text-5xl light-font pl-16 pt-40 pb-10 relative`}
            >
              <li className={`${styles.hiddenNavItem} mb-8`}>
                {isChinese ? (
                  <Link to={`/${queryString}`}>主頁</Link>
                ) : (
                  <Link to={`/en${queryString}`}>Home</Link>
                )}
              </li>
              <li
                className={`${styles.hiddenNavItem} ${styles.discoverStories} brand-blue relative mb-12 pr-4 flex justify-between`}
              >
                <span>Discover BMW Stories</span>
                <span className="self-center w-16 transform rotate-90">
                  <Arrow />
                </span>
              </li>
              <NavHiddenItem
                en="Adventure"
                cn="敢於冒險"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
              <NavHiddenItem
                en="Freedom"
                cn="遨翔自得"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
              <NavHiddenItem
                en="Independence"
                cn="自主創新"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
              <NavHiddenItem
                en="Inspiration"
                cn="啟迪靈感"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
              <NavHiddenItem
                en="Progress"
                cn="一瞬千里"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
              <NavHiddenItem
                en="Wisdom"
                cn="遠見卓識"
                isChinese={isChinese}
                closeMenu={this.closeMenu}
                queryString={queryString}
              />
            </ul>
            <div className="locale-swticher pl-16 mt-10">
              {isChinese ? (
                <Link
                  to={
                    location.pathname === "/tc"
                      ? `${location.pathname.replace(
                          "/tc/",
                          "/en"
                        )}${queryString}`
                      : location.pathname === "/tc/"
                      ? `${location.pathname.replace(
                          "/tc",
                          "/en"
                        )}${queryString}`
                      : `/en${location.pathname}${queryString}`
                  }
                >
                  ENG
                </Link>
              ) : (
                <Link
                  to={`${location.pathname.replace("/en/", "/")}${queryString}`}
                >
                  中文
                </Link>
              )}
            </div>
          </div>
        </nav>
      </>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return {
    switchLanguage: lang => dispatch(setLocale(lang)),
    setActivePillar: pillar => dispatch(setActivePillar(pillar)),
    updateSlide: num => dispatch(updateSlide(num)),
    activateSlider: bool => dispatch(activateSlider(bool)),
    setQueryString: query => dispatch(setQueryString(query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)

function NavHiddenItem({ isChinese, cn, en, closeMenu, queryString }) {
  const handleClick = () => {
    closeMenu()
    const target = en.toLowerCase()
    if (target === "independence") {
      if (isChinese) {
        navigate(`/independence${queryString}`)
      } else {
        navigate(`/en/independence${queryString}`)
      }
    } else {
      if (typeof window !== "undefined") {
        gsap.to(window, {
          duration: 1,
          scrollTo: { y: `#${target}`, offsetY: 250 },
        })
      }
    }
  }
  return (
    <li
      className={`${styles.hiddenNavItem} text-3xl mb-12 cursor-pointer`}
      onClick={handleClick}
    >
      {isChinese ? (
        <>
          <span className="mr-2">心悅</span>
          <span className="bold-font">{cn}</span>
        </>
      ) : (
        <>
          <span className="mr-2">Embrace</span>
          <span className="bold-font">{en}</span>
        </>
      )}
    </li>
  )
}

function NavItem({
  isChinese,
  cn,
  en,
  setActivePillar,
  updateSlide,
  activateSlider,
  queryString,
}) {
  const handleClick = () => {
    const target = en.toLowerCase()
    if (target === "independence") {
      if (isChinese) {
        navigate(`/independence${queryString}`)
      } else {
        navigate(`/en/independence${queryString}`)
      }
    } else {
      if (typeof window !== "undefined") {
        gsap.to(window, {
          duration: 1,
          scrollTo: { y: `#${target}`, offsetY: 250 },
          onComplete: () => {
            setActivePillar(target)
            activateSlider(true)
            updateSlide(0)
          },
        })
      }
    }
  }
  return (
    <li
      className={`light-font uppercase mb-8 ${styles.navItem}`}
      onClick={handleClick}
    >
      {isChinese ? (
        <>
          <span className="mr-2">心悅</span>
          <span className="bold-font">{cn}</span>
        </>
      ) : (
        <>
          <span className="mr-2">Embrace</span>
          <span className="bold-font">{en}</span>
        </>
      )}
    </li>
  )
}
