import {
  SET_LOCALE,
  SET_ACTIVE_PILLAR,
  UPDATE_SLIDE,
  ACTIVATE_SLIDER,
  SET_QUERY_STRING,
  TOGGLE_VIDEO_MODAL,
} from "./type"

export const setLocale = locale => ({
  type: SET_LOCALE,
  locale,
})

export const setActivePillar = pillar => ({
  type: SET_ACTIVE_PILLAR,
  pillar,
})

export const updateSlide = num => ({
  type: UPDATE_SLIDE,
  num,
})

export const activateSlider = bool => ({
  type: ACTIVATE_SLIDER,
  bool,
})

export const setQueryString = query => ({
  type: SET_QUERY_STRING,
  query,
})

export const toggleVideoModa = bool => ({
  type: TOGGLE_VIDEO_MODAL,
  bool
})