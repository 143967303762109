/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"

import "./layout.css"
import Navigation from "./Navigation"
import Footer from "./Footer"
import loadStates from "../hocs/loadStates"
import Modal from "./Modal"

const Layout = ({ children, lang, location, context }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  if (typeof window !== "undefined") {
    if (lang.locale === "tc") {
      document.querySelector("body").classList.add("tc")
    } else {
      document.querySelector("body").classList.remove("tc")
    }
  }

  const {
    modal: { opened },
  } = useSelector(state => state)

  return (
    <>
      <div>
        <Navigation location={location} context={context} />
        <main>{children}</main>
        <Footer />
        {opened ? <Modal /> : null}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default loadStates(Layout)
