import React, { Component } from "react"
import styles from "./index.module.scss"
import loadStates from "../../hocs/loadStates"

class Footer extends Component {
  render() {
    const { locale } = this.props.lang
    const isChinese = locale === "tc"
    return (
      <footer className="bg-brand-grey md:py-24 md:px-10 w-full px-16 py-12 text-white">
        <div className="md:flex-col flex">
          <div className="md:w-full md:mb-20 w-8/12">
            <p className="captions md:mb-10 mb-8">© BMW Hong Kong 2021</p>
            <p className="captions mb-4">{isChinese ? "注意:" : "Notes:"}</p>
            {isChinese ? (
              <ul>
                <li className={`captions ${styles.notes} mb-4 md:mb-10`}>
                  實際規格可能與上述圖片有所不同 所有價格只供參考,
                  以寶馬汽車(香港)有限公司的最終確認為準。
                </li>
                <li className={`captions ${styles.notes} mb-4 md:mb-10`}>
                  寶馬汽車(香港)有限公司保留隨時終止優惠及修改其條款及細則之權利而無須另行通知。
                </li>
                <li className={`captions ${styles.notes}`}>
                  如有任何爭議，寶馬汽車(香港)有限公司保留最終決定權。
                </li>
              </ul>
            ) : (
              <ul>
                <li className={`captions ${styles.notes} mb-4 md:mb-10`}>
                  The model illustrated may include optional equipment subject
                  to availability of your dealer
                </li>
                <li className={`captions ${styles.notes} mb-4 md:mb-10`}>
                  BMW Concessionaires (HK) Limited reserves the right to
                  terminate or amends its Terms and Conditions at any time
                  without prior notice
                </li>
                <li className={`captions ${styles.notes}`}>
                  In case of any dispute, the decision of BMW Concessionaires
                  (HK) Ltd. shall be final
                </li>
              </ul>
            )}

            <div className="copyrights-link mt-10">
              <div className="captions underline">
                <a
                  href="https://www.bmwhk.com/zh/footer/metanavigation/legal-disclaimer-pool/privacy-policy.html"
                  target="_blank"
                  className="mr-4"
                >
                  Privacy Statement
                </a>{" "}
                <a href="https://www.bmwhk.com/zh/index.html" target="_blank">
                  BMWHK.com
                </a>
              </div>
            </div>
          </div>
          <div className="md:w-full w-4/12">
            <div className="md:flex-col md:text-center flex justify-end">
              <span className="captions md:w-full md:mb-10 md:text-3xl mr-6">
                BMWHK on the social web
              </span>
              <div className="md:justify-center flex">
                <div className={`${styles.socialIcon} w-10 md:mr-8 mr-4`}>
                  <a href="https://www.facebook.com/BMWHK" target="_blank">
                    <svg viewBox="0 0 24 24">
                      <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
                    </svg>
                  </a>
                </div>
                <div className={`${styles.socialIcon} w-10 md:mr-10 mr-6`}>
                  <a
                    href="https://www.instagram.com/bmwhongkong/"
                    target="_blank"
                  >
                    <svg viewBox="0 0 512 512">
                      <g>
                        <g>
                          <path
                            d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
			C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
			h192c61.76,0,112,50.24,112,112V352z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
			c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="393.6" cy="118.4" r="17.056" />
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className={`${styles.socialIcon} w-10 md:mr-10 mr-6`}>
                  <a
                    href="https://www.youtube.com/user/BMWHONGKONG"
                    target="_blank"
                  >
                    <svg viewBox="0 0 461.001 461.001">
                      <path
                        d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
	c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
	C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
	c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                      />
                    </svg>
                  </a>
                </div>
                <div className={`${styles.socialIcon} w-10`}>
                  <a
                    href="https://www.linkedin.com/company/bmw-concessionaires-hk-limited/"
                    target="_blank"
                  >
                    <svg viewBox="0 0 382 382">
                      <path
                        d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
	C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
	H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
	c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
	s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
	c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
	c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
	c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
	L341.91,330.654L341.91,330.654z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default loadStates(Footer)
