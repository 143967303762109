import React from "react"
import { useSelector, useDispatch } from "react-redux"
import Player from "@vimeo/player"
export default function Modal() {
  const {
    modal: { opened },
  } = useSelector(state => state)
  const dispatch = useDispatch()

  const video = React.useRef(null)

  React.useEffect(() => {
    const player =
      video.current &&
      new Player(video.current, {
        id: "https://vimeo.com/486230482/5283cc0f53",
        responsive: true,
        autoplay: false,
      })
  }, [])

  return (
    <div
      className={`z-1000 fixed top-0 left-0 w-full h-screen duration-300 ease-out transition-all flex flex-col justify-center ${
        opened ? "visible opacity-100" : "invisible opacity-0"
      }`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-75" />
      <div
        className="cross-container z-1000 xxl:p-16 absolute top-0 right-0 p-8 cursor-pointer"
        onClick={() =>
          dispatch({
            type: "TOGGLE_VIDEO_MODAL",
            opened: false,
          })
        }
      >
        <svg viewBox="0 0 612 612" className="w-10 text-white fill-current">
          <g>
            <g id="cross">
              <g>
                <polygon
                  points="612,36.004 576.521,0.603 306,270.608 35.478,0.603 0,36.004 270.522,306.011 0,575.997 35.478,611.397 
				306,341.411 576.521,611.397 612,575.997 341.459,306.011 			"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="relative z-10 self-center w-full">
        <div className="video-container md:w-full w-4/12 mx-auto">
          <div className="video" ref={video}></div>
        </div>
      </div>
    </div>
  )
}
